import { View, Text, StyleSheet, Linking } from 'react-native';
import { RadioButton, RadioGroup } from '../components/RadioButton';
import { TextInput } from 'react-native-web';


const TextInputQuestion = (props) => {
    const { content, title, id, description, inputMode, maxLength, placeholder, onChange, errorMessage } = props;
    const handleChange = newText => {
        onChange?.(newText);
    };

    return (
        <View style={[styles.parentView, props.style]}>
            {title && <Text style={styles.title}>{title}</Text>}
            {description && <Text style={styles.title}>{description}</Text>}
            {errorMessage && <Text style={styles.error}>{errorMessage}</Text>}
            <TextInput placeholder={placeholder}
                style={styles.textInput}
                onChangeText={handleChange}
                inputmode={inputMode}
                value={content}
                maxLength={maxLength || 1000}></TextInput>
        </View>);
}

const styles = StyleSheet.create(
    {
        parentView: {
            borderColor: "blue",
            flexDirection: "column",
            paddingBottom: 20,
            marginVertical: 20,
        },
        textInput: {
            borderRadius: 7,
            borderColor: "rgba(11, 60, 73, 0.35)",
            borderWidth: 1,
            width: "80%",
            height: 40,
            marginVertical: 10,
            paddingHorizontal: 15
        },
        title: {
            color: "#111111",
            fontSize: 20,
            lineHeight: "100%",
            fontWeight: "600",
            marginVertical: 10,
            flex: 2
        },
        error: {
            color: "red",
            fontSize: 14,
            lineHeight: "100%",
            fontWeight: "600",
            marginVertical: 10,
            opacity: 0.75,

        },
    }
)

export default TextInputQuestion;