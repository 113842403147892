import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import AutoTab from '../components/AutoTab';
import { View, Text, Pressable, TextInput, StyleSheet, Image, Linking } from "react-native";
import { useState, useCallback, useEffect, useMemo } from 'react';
import { AutoTabType } from '../constants/autoTabType';
import Footer from '../components/Footer';
import { isMobile } from '../helpers/deviceUtils';
import validator from 'validator';
import { ERROR_MESSAGES } from '../constants/errorMessages';
import { INPUT_TYPE } from '../constants/inputType';
import axios from 'axios';
import { scale } from 'react-native-size-matters';
import { QuoteInfoType } from '../constants/quoteInfoType';
import { useSearchParams } from 'react-router-dom';
import { RadioButton, RadioGroup } from '../components/RadioButton';
import { CheckBox, FlatList } from 'react-native-web';
import MultiChoiceQuestion from '../components/MultiChoiceQuestion';
import SingleChoiceQuestion from '../components/SingleChoiceQuestion';
import TextInputQuestion from '../components/TextInputQuestion';

const Tab = createBottomTabNavigator();

const isMobileDevice = isMobile();
const CarInsuranceInfo = [[{
    title: "Hi, let's start with a few questions",
    description: "How many days per week can you get to the gym?",
    errorMessage: "",
    inputMode: "numeric",
    type: INPUT_TYPE.NUMBER,
    isComplete: false,
    content: "",
    maxLength: 1,
    id: 1,
},
{
    title: "Do you currently attend a gym?",
    selectedOption: "",
    isComplete: false,
    options: [
        "Yes",
        "No",
    ],
    id: 2,
},
{
    title: "What is your favorite part of working out",
    selectedOptions: [],
    isComplete: false,
    options: [
        "cardio",
        "weight lifting",
        "group workouts",
        "yoga",
        "alternatives",
    ],
    id: 3,
},
{
    title: "How much weight is your goal to loose",
    errorMessage: "",
    inputMode: "numeric",
    type: INPUT_TYPE.NUMBER,
    isComplete: false,
    content: "",
    id: 4,
}],
[{
    title: "How many personal traning sessions would be best for you per week?",
    description: "",
    errorMessage: "",
    inputMode: "numeric",
    type: INPUT_TYPE.NUMBER,
    isComplete: false,
    content: "",
    maxLength: 2,
    id: 1,
}, {
    title: "Do you have a preference on a male or female trainer?",
    selectedOption: "",
    isComplete: false,
    options: [
        "Male",
        "Female",
    ],
    id: 2,
}],
[
    {
        title: "Please advise any more information that may help our consulants assist with your enquiry",
        placeholder: "",
        inputMode: "text",
        isComplete: false,
        content: "",
        id: 1,
    },
    {
        title: "Email address",
        placeholder: "Enter your email address",
        inputMode: "email",
        type: INPUT_TYPE.EMAIL,
        isComplete: false,
        errorMessage: "",
        content: "",
        id: 2,
    },
    {
        title: "Phone number",
        placeholder: "(___) ___ ____",
        errorMessage: "",
        inputMode: "tel",
        type: INPUT_TYPE.PHONE,
        content: "",
        maxLength: 20,
        id: 3,
    }],
]

const CarInsuranceKeys = Object.keys(CarInsuranceInfo);

function InfoForm({ route, navigation }) {
    const [sectionIndex, setSectionIndex] = useState(0);
    const [blockIndex, setBlockIndex] = useState(0);
    const [searchParams] = useSearchParams();
    const zipCode = searchParams.get('zipCode')
    const [autoData, setAutoData] = useState(Object.assign({}, CarInsuranceInfo));
    const [infoData, setInfoData] = useState(CarInsuranceInfo[0])
    const styles = isMobileDevice ? mobileStyles : desktopStyles;

    useEffect(() => {
        sendQuote();
    }, [sectionIndex]);

    const sendQuote = async () => {
        if (sectionIndex >= CarInsuranceKeys.length) {
            let data = ""
            for (let index = 0; index < CarInsuranceKeys.length; index++) {
                const quoteSection = CarInsuranceInfo[CarInsuranceKeys[index]];
                for (let quoteSectionIndex = 0; quoteSectionIndex < quoteSection.length; quoteSectionIndex++) {
                    data += generateQuoteInfo(quoteSection[quoteSectionIndex]);
                }
            }

            console.log(data);

            try {

                const response = await axios.post("https://api.emailjs.com/api/v1.0/email/send", {
                    service_id: "service_v6jln6h",
                    template_id: "template_93h26r9",
                    user_id: "23lB37zM51IUM9_c3",
                    template_params: {
                        message: data
                    }
                })

                console.log(response)
            } catch (error) {
                console.log(error);
            }
        }
    }

    const generateQuoteInfo = (item) => {
        if (item.contents) {
            let quoteInfos = "";
            for (let index = 0; index < item.contents.length; index++) {
                const quoteInfo = generateQuoteInfo(item.contents[index]);
                quoteInfos += quoteInfo;
            }

            return quoteInfos;
        }

        const question = item.description || item.title || item.placeholder;
        const answer = item.selectedOptions != null ? item.selectedOptions.join(',') : (item.selectedOption || item.content);

        return `${question}${question.trim().endsWith("?") ? "" : ":"} ${answer}\n`;
    }

    const onPress = useCallback((isNext = true) => () => {
        if (infoData && isNext) {
            infoData.forEach((data, index) => {
                infoData[index].errorMessage = "";
                if (data.selectedOption != null) {
                    if (data.selectedOption === "") {
                        infoData[index].errorMessage = ERROR_MESSAGES.SELECT;
                    }
                    return;
                }

                if (data.selectedOptions != null) {
                    if (data.selectedOptions.length === 0) {
                        infoData[index].errorMessage = ERROR_MESSAGES.SELECT;
                    }

                    return;
                }

                if (data.content == "") {
                    infoData[index].errorMessage = ERROR_MESSAGES.GENERIC;
                    return;
                }
                isDataValid(infoData[index]);
            });

            const errorIndex = infoData.findIndex(data => data.errorMessage != "" && data.errorMessage != null);

            if (errorIndex >= 0) {
                setAutoData(Object.assign({}, CarInsuranceInfo));
                return;
            }
        }

        if (sectionIndex < CarInsuranceKeys.length) {
            const newIndex = sectionIndex + (isNext ? 1 : -1);
            setSectionIndex(newIndex);
            setInfoData(CarInsuranceInfo[newIndex]);
        }
    }, [sectionIndex, infoData]);

    const renderTitle = (title) => {
        return (
            <Text style={styles.title}>{title}</Text>
        )
    }

    const renderDescription = (description, customStyle) => {
        return (
            <Text style={[styles.description, customStyle]}>{description}</Text>
        )
    }

    const renderError = (errorMessage) => {
        return (
            <Text style={[styles.description, { color: "red" }]}>{errorMessage}</Text>
        )
    }

    const renderNextButton = (isActive = true) => {
        return (
            <Pressable style={[styles.button, isActive && styles.buttonActive]}
                disabled={!isActive}
                onPress={onPress()}>
                <Text style={[styles.buttonContent]}>{"NEXT"}</Text>
            </Pressable>
        )
    }

    const renderBackButton = () => {
        return (
            <Pressable style={[styles.button, styles.buttonActive, { alignSelf: 'flex-start' }]}
                onPress={onPress(false)}>
                <Text style={[styles.buttonContent]}>{"BACK"}</Text>
            </Pressable>
        )
    }

    const isDataValid = (item) => {
        let isValid = true;
        if (item.contents) {
            for (let i = 0; i < item.contents.length; i++) {
                isValid = isDataValid(item.contents[i]);
                if (!isValid) {
                    return false;
                }
            }
        }

        if (item.content) {
            item.errorMessage = "";
            if (item.type === INPUT_TYPE.EMAIL) {
                isValid = validator.isEmail(item.content);
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.EMAIL;

                    return false;
                }
            }

            if (item.type === INPUT_TYPE.PHONE) {
                isValid = validator.isMobilePhone(item.content);
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.PHONE;

                    return false;
                }
            }

            if (item.type === INPUT_TYPE.ZIP_CODE) {
                isValid = validator.isPostalCode(item.content);
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.ZIP_CODE;
                    return false;
                }
            }

            if (item.type === INPUT_TYPE.AGE) {
                isValid = validator.isNumeric(item.content) && item.content.length < 4;
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.AGE;
                    return false;
                }
            }

            if (item.type === INPUT_TYPE.YEAR) {
                isValid = validator.isNumeric(item.content) && item.content.length === 4;
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.YEAR;
                    return false;
                }
            }

            if (item.type === INPUT_TYPE.NUMBER) {
                isValid = validator.isNumeric(item.content);
                if (!isValid) {
                    item.errorMessage = ERROR_MESSAGES.GENERIC;
                    return false;
                }
            }

            // if (item.type === INPUT_TYPE.DATE) {
            //     isValid = validator.isDate(item.content, {
            //         format: "YYYY/MM/DD",
            //     });
            //     if (!isValid) {
            //         item.errorMessage = ERROR_MESSAGES.DATE;
            //         return false;
            //     }
            // }
        }

        return true;
    }



    const renderCompleteView = () => {
        return (
            <View style={styles.completeView}>
                {renderTitle(messages.complete)}
                <Image source={require('../assets/complete.png')}
                    style={styles.completeImage}
                    resizeMode='contain' />
                {renderDescription(messages.completeDescription, { textAlign: "center" })}
            </View>
        )
    }

    const onMultiChoiceChange = useCallback(item => (option, isSelected) => {
        if (isSelected) {
            if (!item.selectedOptions.includes(option)) {
                item.selectedOptions.push(option);
            }
        } else {
            const index = item.selectedOptions.indexOf(option);
            if (index >= 0) {
                item.selectedOptions.splice(index, 1);
            }
        }

        setAutoData(Object.assign({}, CarInsuranceInfo));

    });

    const onSingleChoiceChange = useCallback(item => (option) => {
        item.selectedOption = option;
        setAutoData(Object.assign({}, CarInsuranceInfo));
    });

    const onTextChange = useCallback(item => (newText) => {
        item.content = newText;
        setAutoData(Object.assign({}, CarInsuranceInfo));
    })

    const renderListItem = useCallback(({ item }) => {
        if (item.selectedOptions != null) {
            return <MultiChoiceQuestion {...item} onChange={onMultiChoiceChange(item)} />;
        } else if (item.selectedOption != null) {
            return <SingleChoiceQuestion {...item} onChange={onSingleChoiceChange(item)} />;
        } else if (item.content != null) {
            return <TextInputQuestion {...item} onChange={onTextChange(item)} />;
        }

        return <View></View>;
    }, []);

    return (
        <View style={styles.detailView}>
            {sectionIndex >= CarInsuranceKeys.length ? renderCompleteView()
                : <View style={styles.detailContent}>
                    <FlatList data={infoData}
                        renderItem={renderListItem}
                        keyExtractor={item => item.id}
                        style={{ height: "80%" }}
                    >

                    </FlatList>
                    <View style={{ flexDirection: 'row' }}>
                        {sectionIndex != 0 && renderBackButton()}
                        {renderNextButton()}
                    </View>
                </View>}
        </View>
    );
}

const baseStyles = StyleSheet.create(
    {
        title: {
            color: "#111111",
            fontSize: 20,
            lineHeight: "100%",
            fontWeight: "600",
            marginVertical: 10
        },
        description: {
            color: "#111111",
            fontSize: 14,
            lineHeight: "100%",
            fontWeight: "600",
            marginVertical: 10,
            opacity: 0.75,
        },
        button: {
            borderRadius: 60,
            backgroundColor: "rgba(0, 74, 173, 0.45)",
            flex: 1,
            marginHorizontal: 5,
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
            marginBottom: 15,
            width: 200,
            maxWidth: 200,
            height: 50,
            alignSelf: 'flex-end'
        },
        buttonActive: {
            backgroundColor: "#004AAD"
        },
        buttonContent: {
            color: "#FFFFFF",
            fontSize: 14,
            lineHeight: "100%",
            fontWeight: "700",
            padding: 14,
        },
        optionButton: {
            marginVertical: scale(5),
            height: scale(40),
            borderRadius: 7,
            borderColor: "rgba(11, 60, 73, 0.35)",
            borderWidth: 1,
            backgroundColor: "#F0FAF5",
            flex: 1,
            marginHorizontal: 5,
            alignItems: "center",
            width: "100%"
        },
        optionButtonActive: {
            borderWidth: 2,
            borderColor: "#70E189",
            backgroundColor: "#B8F0C4"
        },
        optionButtonContent: {
            color: "#000000",
            fontSize: 14,
            lineHeight: "100%",
            fontWeight: "600",
            marginVertical: 15,
            opacity: 0.75
        },
        textInputView: {
            marginVertical: 5,
            width: "100%",
            height: 40,
            flexDirection: "row"
        },
        textInput: {
            borderRadius: 7,
            borderColor: "rgba(11, 60, 73, 0.35)",
            borderWidth: 1,
            width: "100%",
            paddingHorizontal: 15
        },
        columnDirectionView: {
            flexDirection: "column",
            width: "100%",
            alignItems: "flex-start",
        },
        completeView: {
            flexDirection: "column",
            width: 500,
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: 10,
            padding: 20
        },
        completeImage: {
            width: "80%",
            height: 200
        },
        detailView: {
            width: "100%",
            height: window.innerHeight,
            backgroundColor: "rgba(0,0,0,0.7)",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute"
        },
        detailContent: {
            width: 800,
            backgroundColor: "white",
            height: window.innerHeight * 0.8,
            borderRadius: 10,
            borderColor: "rgba(11, 60, 73, 0.50)",
            borderWidth: 1,
            padding: scale(20),
        },
    });

const styles = StyleSheet.create({
    app: {
        flex: 1,
        padding: 24,
        justifyContent: "center"
    },
    label: {
        fontWeight: "bold"
    },
    pizzaCrust: {
        flexDirection: "row",
        marginTop: 8,
        marginBottom: 16
    },
    radioBtn: {
        marginRight: 16
    },
    focused: {
        color: "red"
    },
    pizzaCrustVertical: {
        marginTop: 8
    },
    radioBtnVertical: {
        marginTop: 8
    },
    radiolabel: {
        marginLeft: 8
    }
});

const desktopStyles = StyleSheet.create({
    ...baseStyles,
    button: {
        ...baseStyles.button,
        alignSelf: "flex-end"
    },
})



const mobileStyles = StyleSheet.create({
    ...baseStyles,
    button: {
        ...baseStyles.button,
        width: "100%"
    },
})
const messages = {
    complete: "Complete!",
    completeDescription: "One of our partners will be in contact with you shortly to discuss with you the next steps."
}

export default InfoForm;