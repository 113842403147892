

export const ERROR_MESSAGES = {
    ZIP_CODE: "Please enter a valid Zip Code",
    EMAIL: "Please enter a valid email address",
    PHONE: "Please enter a valid phone number",
    AGE: "Please enter your age",
    YEAR: "Please enter a valid year",
    DATE: "Please enter your date of birth",
    SELECT: "Please select one",
    GENERIC: "Invalid data",
}