import { View, Text, StyleSheet, ImageBackground, Button, Image } from 'react-native';
import { isMobile } from '../helpers/deviceUtils';


function PrivacyPage({ navigation }) {

    return (
        <View style={styles.root}>
            <Text style={[styles.description, { paddingVertical: 10, fontSize: 22, fontWeight: "700", textAlign: "center" }]}>{`Privacy Policy `}</Text>
            <Text style={[styles.description, { paddingVertical: 10, fontSize: 20, fontWeight: "600", textAlign: "center" }]}>{`Effective as of April 22, 2023`}</Text>

            <Text style={styles.description}>{`At TrainMeLocal.com (""TrainMeLocal.com"", ""we"", ""our"" or ""us""), we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services. By accessing or using our website or services, you agree to the terms and conditions of this Privacy Policy.`}</Text>
            <Text style={styles.title}>{`1. Information We Collect`}</Text>
            <Text style={styles.description}>{`When you visit our website or use our services, we may collect certain information from you, including:`}</Text>
            <Text style={styles.subDescription}>{`\t- Personal Information: This may include your name, email address, phone number, and any other information you voluntarily provide to us when contacting us or filling out forms on our website.`}</Text>
            <Text style={styles.subDescription}>{`\t- Usage Data: We may also collect information about how you access and use our website and services, including your IP address, browser type, operating system, referral URLs, and other usage data.`}</Text>
            <Text style={styles.title}>{`2. Use of Information`}</Text>
            <Text style={styles.description}>{`We may use the information we collect from you for the following purposes:`}</Text>
            <Text style={styles.subDescription}>{`\t- To communicate with you: We may use your contact information to respond to your inquiries, provide customer support, and send you updates and notifications about our services.`}</Text>
            <Text style={styles.subDescription}>{`\t- To improve our services: We may use usage data to analyze trends, monitor the effectiveness of our website and services, and make improvements.`}</Text>
            <Text style={styles.subDescription}>{`\t- To comply with legal obligations: We may use your information to comply with applicable laws, regulations, legal processes, or governmental requests.`}</Text>
            <Text style={styles.title}>{`3. Disclosure of Information`}</Text>
            <Text style={styles.description}>{`We may disclose your information to third parties in the following circumstances:`}</Text>
            <Text style={styles.subDescription}>{`\t- With your consent: We may share your information with third parties when you give us explicit consent to do so.`}</Text>
            <Text style={styles.subDescription}>{`\t- Service Providers: We may share your information with third-party service providers who help us operate our website, provide our services, or conduct business on our behalf.`}</Text>
            <Text style={styles.subDescription}>{`\t- Legal Compliance: We may disclose your information to comply with applicable laws, regulations, legal processes, or governmental requests.`}</Text>
            <Text style={styles.title}>{`4. Data Security`}</Text>
            <Text style={styles.description}>{`We take reasonable measures to protect the security of your information and prevent unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee the absolute security of your information.`}</Text>
            <Text style={styles.title}>{`5. Your Choices`}</Text>
            <Text style={styles.description}>{`You can choose not to provide us with certain information, but this may limit your ability to access certain features of our website or use our services. You may also opt-out of receiving marketing communications from us by following the instructions provided in such communications.`}</Text>
            <Text style={styles.title}>{`6. Changes to this Privacy Policy`}</Text>
            <Text style={styles.description}>{`We may update this Privacy Policy from time to time by posting a new version on our website. We will notify you of any material changes to this Privacy Policy by posting a notice on our website or sending you an email notification. Your continued use of our website or services after such changes constitutes your acceptance of the updated Privacy Policy.`}</Text>
            <Text style={styles.title}>{`7. Contact Us`}</Text>
            <Text style={styles.description}>{`If you have any questions or concerns about this Privacy Policy or our data practices, you may contact us at letsgo@trainmelocal.com.`}</Text>

        </View>
    );
}

const styles = StyleSheet.create(
    {
        root: {
            backgroundColor: 'white',
            padding: 50
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            position: "relative",
            paddingVertical: 10,
        },
        description: {
            color: "#0B3C49",
            fontSize: 16,
            fontWeight: "500",
            position: "relative",
            paddingVertical: 10,
        },
        subDescription: {
            color: "#0B3C49",
            fontSize: 14,
            fontWeight: "400",
            position: "relative",
        },
    }
)

export default PrivacyPage;
