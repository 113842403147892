import { View, Text, StyleSheet, ImageBackground, Button, TextInput } from 'react-native';
import { useState, useCallback } from 'react';
import Footer from '../components/Footer';
import validator from 'validator';
import { ERROR_MESSAGES } from '../constants/errorMessages';
import { isMobile } from '../helpers/deviceUtils';
import { useNavigate } from 'react-router-dom';
import AutoInsuranceTabs from './autoInsurance';
import InfoForm from './infoForm';

const isMobileDevice = isMobile();


function AutoHomePage({ navigation }) {
    const [zipCode, setZipCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showInfoView, setShowInfoView] = useState(false);
    const styles = isMobileDevice ? mobileStyles : desktopStyles;
    const navigate = useNavigate();

    const onStartButtonPress = useCallback(() => {
        const isValid = validator.isPostalCode(zipCode, "US");
        if (isValid) {
            setErrorMessage("");
            setShowInfoView(true);
        } else {
            setErrorMessage(ERROR_MESSAGES.ZIP_CODE)
        }
    }, [setErrorMessage, navigate, zipCode])

    return (
        <View style={styles.root}>

            <View style={styles.contentView}>
                <ImageBackground source={require('../assets/background.png')}
                    style={styles.image} />

                <View style={styles.mainContent} >
                    <Text style={styles.title}>{messages.title}</Text>
                    <Text style={styles.description}>{messages.description}</Text>
                    {errorMessage && <Text style={styles.error}>{errorMessage}</Text>}
                    <View style={styles.inputView}>
                        <TextInput style={styles.textInput}
                            placeholder={messages.zipCode}
                            placeholderTextColor={'rgba(25,28,31,0.5)'}
                            value={zipCode}
                            inputMode='numeric'
                            maxLength={5}
                            onChangeText={(newValue) => {
                                setZipCode(newValue);
                            }} />

                        <View style={styles.buttonView}>
                            <Button title={messages.seeQuotes} color={'rgba(0,0,0)'}
                                onPress={onStartButtonPress} />
                        </View>
                    </View>
                </View>
                {showInfoView && <InfoForm />}
            </View>
            <Footer navigation={navigation} style={styles.footer}>

            </Footer>

        </View>
    );
}

const baseStyles = StyleSheet.create(
    {
        root: {
            backgroundColor: '#91E9EC',
        },
        header: {
            height: window.innerHeight * 0.1,
            backgroundColor: 'white',
            justifyContent: "center"
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "800"
        },
        contentView: {
            height: window.innerHeight,
            width: "100%",
        },
        image: {
            width: "100%",
            height: '100%',
            resizeMode: 'cover',
        },
        description: {
            color: "white",
            fontWeight: "700",
        },
        error: {
            color: "red",
            fontWeight: "400",
            lineHeight: 24
        },
        inputView: {
            flexDirection: "column",
            marginTop: 40
        },
        textInput: {
            backgroundColor: "white",
            borderRadius: 10,
            fontWeight: 400,
            padding: 15,
        },
        footer: {
            position: "absolute",
            marginTop: window.innerHeight * 0.94,
            flexDirection: "row",
            justifyContent: "center",
            width: "100%"
        },
        buttonView: {
            backgroundColor: "#004AAD",
            marginTop: 20,
            position: "relative",
            borderRadius: 10,
            paddingVertical: 5,
            paddingHorizontal: 15,
            width: 150
        },
    }
)

const desktopStyles = StyleSheet.create(
    {
        ...baseStyles,
        header: {
            ...baseStyles.header,
            width: '100%',
        },
        headerContent: {
            marginLeft: 215,
            flexDirection: "row"
        },
        title: {
            ...baseStyles.title,
            fontSize: 64,
        },
        contentView: {
            ...baseStyles.contentView,
            justifyContent: "center"
        },
        mainContent: {
            marginLeft: 215,
            maxWidth: 670,
            position: "absolute",
            marginBottom: 200,
        },
        description: {
            ...baseStyles.description,
            fontSize: 64,
            lineHeight: 66
        },
        error: {
            ...baseStyles.error,
            fontSize: 24,
        },
        textInput: {
            ...baseStyles.textInput,
            fontSize: 12,
            width: 220
        },
    }
)


const mobileStyles = StyleSheet.create(
    {
        ...baseStyles,
        root: {
            ...baseStyles.root,
            height: "100%",
            width: '100%',
        },
        header: {
            ...baseStyles.header,
            width: window.innerWidth,
        },
        headerContent: {
            marginLeft: "10%",
            flexDirection: "row",
            alignItems: "center"
        },
        contentView: {
            ...baseStyles.contentView,
            justifyContent: "flex-start"
        },
        mainContent: {
            marginLeft: "10%",
            width: "80%",
            position: "absolute",
            marginTop: 65,
            paddingRight: 20
        },
        description: {
            ...baseStyles.description,
            fontSize: 30,
            lineHeight: 38
        },
        error: {
            ...baseStyles.error,
            fontSize: 20,
        },
        textInput: {
            ...baseStyles.textInput,
            fontSize: 10,
            width: "60%"
        },
    }
)

const messages = {
    title: "TrainMeLocal.com",
    description: "Get into your new routine today!",
    zipCode: "ENTER YOUR ZIP CODE",
    seeQuotes: "START NOW",
}

export default AutoHomePage;
