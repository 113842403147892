import { View, Text, StyleSheet, ImageBackground, Button, Image } from 'react-native';
import { isMobile } from '../helpers/deviceUtils';


function TermsPage({ navigation }) {

    return (
        <View style={styles.root}>
            <Text style={[styles.description, { paddingVertical: 10, fontSize: 22, fontWeight: "700", textAlign: "center" }]}>{`Terms of Use`}</Text>
            <Text style={[styles.description, { paddingVertical: 10, fontSize: 20, fontWeight: "600", textAlign: "center" }]}>{`Effective as of April 22, 2023`}</Text>

            <Text style={styles.description}>{`Welcome to TrainMeLocal.com (""TrainMeLocal.com"", ""we"", ""our"" or ""us""). TrainMeLocal.com offers a platform that connects users with local fitness and wellness services, accessed through Internet-connected computers, televisions, or mobile devices (e.g., mobile phones, tablets), or otherwise (collectively, the “Services”). Before using any of our Services, please read the following important terms and conditions of use (the “Terms of Use”) carefully. The Terms of Use and all policies referenced herein govern your access to and use of the Services and constitute a legal agreement between you and TrainMeLocal.com, applicable whether you are a visitor or a member. By accessing or using the Services, you agree to be bound by these Terms of Use.`}</Text>
            <Text style={styles.title}>{`1. Eligibility and Registration`}</Text>
            <Text style={styles.description}>{`In order to access certain features of the Services, you may be required to register for an account and provide us with certain information, such as a valid email address. By registering for an account, you agree that all registration information you provide is legal, accurate, and complete. You also agree to promptly update your account information with any new information. Your privacy is important to us, and our Privacy Policy contains information about our policies and procedures regarding the collection, use, and disclosure of information we receive from you.`}</Text>
            <Text style={styles.description}>{`You agree not to use false identities or impersonate any other person or use a username or password that you are not authorized to use. TrainMeLocal.com reserves the right to require you to change your username for any reason at any time. You are responsible for safeguarding and maintaining the confidentiality of your username, password, and corresponding account information. You agree not to disclose your password to anyone and to notify TrainMeLocal.com immediately of any unauthorized use of your account.`}</Text>
            <Text style={styles.title}>{`2. License`}</Text>
            <Text style={styles.description}>{`Subject to the terms herein, TrainMeLocal.com grants you a limited, personal, non-exclusive, revocable, non-sublicensable, non-transferable license to use the Services for personal use only, contingent upon your full compliance with the Terms of Use and any other additional terms and conditions set forth by us, including our Privacy Policy. We reserve all rights in and retain full and complete title to the Services and any content made available through them, including all associated intellectual property rights. You agree not to reproduce, modify, adapt, prepare derivative works based on, perform, display, publish, distribute, transmit, broadcast, sell, license, or otherwise exploit any content, software, or services made available through the Services.`}</Text>
            <Text style={styles.title}>{`3. Proprietary Rights`}</Text>
            <Text style={styles.description}>{`The Services contain intellectual property that is proprietary to us or our licensors and protected by United States and foreign laws. TrainMeLocal.com and our licensors own and control all right, title, and interest in the Services, including any content contained therein, trademarks, service marks, copyrights, or patent rights. You acknowledge that no ownership rights are being conveyed to you.`}</Text>
            <Text style={styles.title}>{`4. Use of the Services`}</Text>
            <Text style={styles.description}>{`The Services are made available to you only for the intended purposes as set out herein. You agree not to engage in any activity that could damage, disable, impair, interrupt, or interfere with the Services. You agree to use the Services in compliance with all applicable laws, regulations, and ordinances.`}</Text>
            <Text style={styles.title}>{`5. Termination or Suspension of the Services & Modification of the Terms of Use`}</Text>
            <Text style={styles.description}>{`TrainMeLocal.com reserves the right to modify, discontinue, or terminate the Services or the Terms of Use at any time without advance notice. Modifications to the Terms of Use or Privacy Policy will be posted through the Services or made in compliance with any notice requirements set forth herein. By continuing to use the Services after we have posted any such modifications or provided any required notices, you accept and agree to be bound by such modifications.`}</Text>
            <Text style={styles.title}>{`6. Questions`}</Text>
            <Text style={styles.description}>{`If you have questions about the Terms of Use or Privacy Policy, you can contact us at letsgo@trainmelocal.com.`}</Text>

        </View>
    );
}

const styles = StyleSheet.create(
    {
        root: {
            backgroundColor: 'white',
            padding: 50
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            position: "relative",
            paddingVertical: 10,
        },
        description: {
            color: "#0B3C49",
            fontSize: 16,
            fontWeight: "500",
            position: "relative",
            paddingVertical: 10,
        },
    }
)
export default TermsPage;
