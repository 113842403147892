import { View, Text, StyleSheet, Linking } from 'react-native';
import { RadioButton, RadioGroup } from '../components/RadioButton';


const SingleChoiceQuestion = (props) => {
    const { options, selectedOption, onChange, title, id, errorMessage } = props;
    const handleChange = option => {
        onChange?.(option);
    };

    return (
        <View style={[styles.parentView, props.style]}>
            <Text style={styles.title}>{title}</Text>
            {errorMessage && <Text style={styles.error}>{errorMessage}</Text>}
            <RadioGroup
                value={selectedOption}
                labelID={title}
                onChange={handleChange}
                style={styles.pizzaCrust}
            >
                {options.map(option => (
                    <RadioButton
                        key={option}
                        label={option}
                        value={option}
                        containerStyle={styles.radioBtn}
                    />
                ))}
            </RadioGroup>
        </View>);
}

const styles = StyleSheet.create(
    {
        parentView: {
            borderColor: "blue",
            flexDirection: "column",
            paddingBottom: 20,
            marginVertical: 20,
        },
        pizzaCrust: {
            flexDirection: "row",
            marginTop: 8,
            marginBottom: 16,
            flex: 3
        },
        radioBtn: {
            marginRight: 16
        },
        title: {
            color: "#111111",
            fontSize: 20,
            lineHeight: "100%",
            fontWeight: "600",
            marginVertical: 10,
            flex: 2
        },
        error: {
            color: "red",
            fontSize: 14,
            lineHeight: "100%",
            fontWeight: "600",
            marginVertical: 10,
            opacity: 0.75,

        },
    }
)

export default SingleChoiceQuestion;